<template>
  <div class="report">
    <div class="pad-b20">
      <el-alert
          v-if="!user"
          title="您当前是匿名举报,部分功能受限，建议登录后举报"
          type="info"
          description="登录举报可上传附件凭证，有利于加快举报进程；"
          show-icon>
      </el-alert>
    </div>
    <el-form :rules="formRules" ref="form" :model="form" label-width="120px">
      <el-form-item label="举报对象">
        <el-input :value="target.title" readonly></el-input>
      </el-form-item>
      <el-form-item label="举报类型" prop="reasonid">
        <el-select v-model="form.reasonid" placeholder="请选择举报原因">
          <el-option
              v-for="(item, index) in reasonList"
              :label="item.reasoncontent"
              :value="item.id"
              :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="举报原因" prop="content">
        <el-input type="textarea" v-model="form.content"></el-input>
      </el-form-item>
      <el-form-item class="tel" label="联系电话" prop="tel">
        <el-input v-model="form.tel"></el-input>
      </el-form-item>
      <el-form-item class="email" label="联系邮箱" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <template v-if="user">
        <el-form-item label="附件凭证" prop="email">
          <el-upload
              :http-request="fileUploadFunction"
              action=""
              :limit="3"
              :data="{...uploadEnum.REPORT}"
              :on-exceed="fileCountOver"
              list-type="picture-card"
              :multiple="true"
              :before-upload="beforeUpload"
              :on-success="onUploadSuccess"
              :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
            <div class="el-upload__tip" slot="tip">
              <div>提示：</div>
              <div>1.只能上传jpg/png文件</div>
              <div>2.可上传1~3张图片</div>
            </div>
          </el-upload>
        </el-form-item>
      </template>
      <el-form-item>
        <el-button :loading="submitting" type="primary" @click="onSubmitClick('form')">提交举报
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import validator from '../../resources/js/async-validator'
import uploadEnum from "@/resources/js/uploadenum";

const Type = {
  REQUIRE: 1,
  SERVICE: 2,
  PRODUCT: 3,
  DESIGN: 4,
  PART_TIME: 6,
  DOCUMENT: 5,
};
Object.freeze(Type);

const config = require('../../resources/js/config');
export default {
  name: 'report_form',
  components: {},
  props: {
    type: {
      type: Number,
      required: true,
      validator: function (value) {
        return Object.keys(Type).some(key => Type[key] === value)
      }
    },
    /**
     * 举报对象。至少包含两个属性
     * title
     * id
     */
    target: {
      type: Object
    }
  },
  data() {
    return {
      uploadEnum,
      submitting: false,
      form: {
        content: null,
        tel: null,
        email: null,
        reasonid: null
      },
      formRules: {
        content: [
          {required: true, message: '请输入举报详细原因', trigger: ['blur', 'change']}
        ],
        tel: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              } else {
                validator.validPhone(rule, value, callback)
              }
            }
          }
        ],
        email: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              } else {
                validator.validEmail(rule, value, callback)
              }
            }
          }
        ],
        reasonid: [
          {required: true, message: '请选择举报类型', trigger: ['blur', 'change']}
        ]
      },
      reasonList: []
    }
  },
  watch: {
    type: {
      handler(val) {
        if (val) {
          this.getConditions();
        }
      }
    }
  },
  computed: {},
  methods: {
    beforeUpload(file) {

      const allowType = [
        'image/png',
        'image/jpeg'
      ];
      const isImage =
        allowType.some(type => file.type.startsWith(type))
      ;
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isImage) {
        this.$message.error('上传文件只能是 图片 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 1M!')
      }
      return isImage && isLt2M && this.$message('文件正在上传') && true
    },
    onUploadSuccess(res, file, fileList) {
      if (res.code) {
        this.form.images = fileList;
        this.$message.success('上传成功')
      } else {
        this.$message.error('上传文件失败')
      }
    },
    handleRemove(file, fileList) {
      this.form.images = fileList
    },
    fileCountOver() {
      this.$message.warning('文件数量已达最大限制')
    },
    onSubmitClick(formName) {
      this.$refs[formName].validate()
      .then(res => {
        this.doSubmit()
      })
      .catch(() => {
        this.$message.warning('请完成表单');
        return Promise.reject()
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
    doSubmit() {
      this.submitting = true;
      const {form} = this;
      return this.$request.post({
        reqcode: '1137',
        reqdesc: '举报',
        userid: (this.user && this.user.userId) || null,
        content: form.content,
        tel: form.tel,
        email: form.email,
        type: this.type,
        eid: this.target.id,
        reasonid: form.reasonid,
        imgurls: (form.images || []).map(item => item.response.src).join(',')
      }).then(res => {
        this.$message.success('提交成功!');
        this.resetForm();
        this.$emit('success')
      }).finally(() => {
        this.submitting = false
      })
    },
    getConditions() {
      return this.$request.post({
        reqcode: '1136',
        reqdesc: '举报原因获取',
        type: this.type
      }).then(res => {
        const {data} = res;
        this.reasonList = data
      })
    }
  },
  beforeMount() {
    this.getConditions()
  }
}
</script>

<style scoped lang="scss">
.report {
  /deep/ {
    .el-textarea__inner {
      min-height: 120px !important;
    }

    .tel, .email {
      display: inline-block;
      width: 50%;
    }
  }
}
</style>
